// 引入axios
import axios from 'axios'
import router from '@/router';
import { Loading } from 'element-ui';
// 创建axios默认配置
// 创建实例时配置默认值
const service = axios.create({
    // 测试环境 
    // baseURL: 'http://124.132.152.226:8008/api',

    // 打包环境 
    baseURL: '/api',   

    // 正式环境 
    // baseURL: 'http://124.132.152.209:8008/api',

    // baseURL: 'http://192.168.96.179:8008/api',
    // baseURL: 'https://cw.yxtidc.com.cn/api',
    Headers:{
      'Content-Type':'application/json; charset=utf-8',
      'Authorization': localStorage.getItem('customlogintoken')
    }
})

// 创建一个loading实例
let loadingInstance = null;

// 添加请求拦截器
service.interceptors.request.use((config) => {
  // 设置等待效果
  // console.log(config.url);
  // if(!config.params || !config.params.hideLoading){
  //   console.log('hideLoading');
  //   loadingInstance = Loading.service({
  //     fullscreen:true,
  //     background:'rgba(0, 0, 0, 0.7)',
  //     text:'加载中'
  //   })
  // }



  // 在发送请求之前做些什么
  const token = localStorage.getItem('customlogintoken');
  // config.headers.Authorization = localStorage.getItem('customlogintoken')
  if (token) {
    //在请求头中携带Token
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config
}, (error) => {
  // 隐藏loading效果
  if (loadingInstance) {
    loadingInstance.close();
  }

  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
  // 隐藏loading效果
  if (loadingInstance) {
    loadingInstance.close();
  }

  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  // console.log(response);
  if(response.data.code == 401){
    localStorage.clear('customlogintoken')
    router.push('/login');
  }else{
    return response.data
  }
  
}, (error) => {
  // 隐藏loading效果
  if (loadingInstance) {
    loadingInstance.close();
  }
  
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  // if (error.response && error.response.status === 401 && router.currentRoute.path !== '/login') {
  if (error.response && error.response.status == 401) {
    localStorage.clear('customlogintoken')
    router.push('/login');
  }
  return Promise.reject(error)
})

function deleteData(url){
  return service.delete(url)
}
export default service


