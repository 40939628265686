import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Element from 'element-ui'
import swal from 'sweetalert';

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home,
  //   redirect:'/secure'    //默认重定向到secure
  // },
  {
    path: '*',
    name: '/',
    redirect:'/' 
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect:'/secure',    //默认重定向到secure
    children:[
      // 订购产品
      {path:'/order',name:'order',component:()=>import('../views/product/order.vue')},
      // 云服务器
      {path:'/server',name:'server',component:()=>import('../views/product/server.vue')},
      // 云服务器产品详情  
      {path:'/cloudDeta',name:'cloudDeta',component:()=>import('../views/product/cloudDeta.vue')},
      // 产品详情 
      {path:'/serverEdit',name:'serverEdit',component:()=>import('../views/product/serverEdit.vue')},
      // 套餐升降级 
      {path:'/improve',name:'improve',component:()=>import('../views/product/improve.vue')},
      // 独立服务器
      {path:'/independence',name:'independence',component:()=>import('../views/product/independence.vue')},
      // 其他服务器 
      {path:'/otherProduct',name:'otherProduct',component:()=>import('../views/product/otherProduct.vue')},
      // 机柜租用
      {path:'/cabinet',name:'cabinet',component:()=>import('../views/product/cabinet.vue')},
      // 机柜租用详情 cabinetEdit
      {path:'/cabinetEdit',name:'cabinetEdit',component:()=>import('../views/product/cabinetEdit.vue')},
      {path:'/orderdetail',name:'orderdetail',component:()=>import('../views/product/orderdetail.vue')},
      // 实名认证
      {path:'/authentication',name:'authentication',component:()=>import('../views/account/authentication.vue')},
      // 个人信息
      {path:'/person',name:'person',component:()=>import('../views/account/person.vue')},
      // 安全中心 
      {path:'/secure',name:'secure',component:()=>import('../views/account/secure.vue')},
      //帐单列表
      {path:'/billList',name:'billList',component:()=>import('../views/finance/billList.vue')},
      // 帐单列表查看详情 
      {path:'/billListEdit',name:'billListEdit',component:()=>import('../views/finance/billListEdit.vue')},
      // 账户充值 
      {path:'/recharge',name:'recharge',component:()=>import('../views/finance/recharge.vue')},
      // 交易记录
      {path:'/record',name:'record',component:()=>import('../views/finance/record.vue')},
      // 付款页面 viewcart
      {path:'/viewcart',name:'viewcart',component:()=>import('../views/product/viewcart.vue')},
      {path:'/dome',name:'dome',component:()=>import('../views/product/dome.vue')},
      // 支付成功页面 
      
      {path:'/paysuccess',name:'paysuccess',component:()=>import('../views/product/paysuccess.vue')},
      // 工单页面 
      {path:'/work',name:'authenticworkation',component:()=>import('../views/account/work.vue')},
      // 新增工单
      {path:'/addWork',name:'addWork',component:()=>import('../views/account/addWork.vue')},
      // 工单详情workDateli
      {path:'/workDateli',name:'workDateli',component:()=>import('../views/account/workDateli.vue')},
      // 消息列表 messageList
      {path:'/messageList',name:'messageList',component:()=>import('../views/account/messageList.vue')},
      // 防火墙查询  
      // {path:'/firewall',name:'firewall',component:()=>import('../views/product/firewall.vue')},
      // // 域名白名单 whiteList
      // {path:'/whiteList',name:'whiteList',component:()=>import('../views/website/whiteList.vue')},
      // 漏洞提醒 loophole
      {path:'/loophole',name:'loophole',component:()=>import('../views/website/loophole.vue')},
      // 库存列表 inventory
      // {path:'/inventory',name:'inventory',component:()=>import('../views/website/inventory.vue')},
    ]
  },
  
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history', // 或者 'hash'
  routes
})


// 获取url参数的token方法
function getQueryString(name) {
  let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
      return unescape(r[2]);
  };
  return null;
}
// sub15376803076
router.beforeEach((to, from, next) => {
  // console.log(to);
  // console.log(to.query.saleId,'销售ID');
  // 登录之后检测有token就不跳到login页面,如果没有token就必须跳到登录
  /* 必须调用 `next` */
  let token = localStorage.getItem('customlogintoken')

  if(to.path==='/login'){
    // 判断以该客户登录
      let Ctoken = getQueryString("token");
      console.log(Ctoken);
      let cid = getQueryString("cid");
      console.log("token="+Ctoken);
      if(Ctoken!=null && Ctoken.trim().length>0){
      console.log(cid);
      localStorage.setItem("customlogintoken",Ctoken);
      localStorage.setItem("customusernametitle", cid);
      router.push("/secure");
      return;
      }
    // 判断有没有token
    if(token){
        next('/secure')
        setTimeout('console.clear()', 300)  
    }else{
      next()
      // console.log(to, from, next);
    }
  }else{
    if (token == null || token== undefined || token == '') {
      next({path:'/login'})
      localStorage.clear();
    } else{
      //-----------------------------------------
      if(localStorage.getItem('usersublogin') == '1'){
        // next();
        if(to.path == '/whiteList' || to.path == '/firewall'){
          next()
        }else{
          next('/whiteList')
        }
      }else if(localStorage.getItem('isReal') == '1' || to.path == '/secure' || to.path == '/authentication' ){
        next();
      }else{
        swal({
          title: "请先实名认证",
          text: "Please authenticate your real name first!",
          icon: "warning",
          buttons: {
            cancel: "取消",
            confirm: {
              text: "确认",
              value: true,
              visible: true,
              className: "btn-confirm",
            }
          },
          // dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            // 在这里执行确认按钮点击后的操作
            // swal("Poof! Your imaginary file has been deleted!", {
            //   icon: "success",
            // });
            // localStorage.setItem("isReal","1")
            // console.log("isreal:"+localStorage.getItem("isReal"));
          
              next('/authentication')
            
          } else {
            // swal("Your imaginary file is safe!");
          }
        })
      }
    }

    

    // if (token !== null && token !== '' && token!== undefined) {
    //   // console.log(token,'正确的的token1');
    //   next()
    // }else{
    //   console.log(token,'失效的token');
    //   // window.alert('身份过期,请重新登录')
    //   // Message.error('身份过期,请重新登录')
    //   next({path:'/login'})
    //   // return;
    // }
    // if(localStorage.getItem('isReal') != '1'){
    //     if(localStorage.getItem('isReal') !== '1'){
    //       swal({
    //         title: "请先实名认证",
    //         text: "Please authenticate your real name first!",
    //         icon: "warning",
    //         buttons: {
    //           cancel: "取消",
    //           confirm: {
    //             text: "确认",
    //             value: true,
    //             visible: true,
    //             className: "btn-confirm",
    //           }
    //         },
    //         // dangerMode: true,
    //       }).then((willDelete) => {
    //         if (willDelete) {
    //           // 在这里执行确认按钮点击后的操作
    //           // swal("Poof! Your imaginary file has been deleted!", {
    //           //   icon: "success",
    //           // });
    //           // localStorage.setItem("isReal","1")
    //           // console.log("isreal:"+localStorage.getItem("isReal"));
            
    //             next('/authentication')
              
    //         } else {
    //           // swal("Your imaginary file is safe!");
    //         }
    //       })
    //   }
    // }

  }
})
//后置守卫
router.afterEach((to,from)=>{
  // document.title = to.meta.title || '三支网-客户管理系统'
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
    if ( resolve || reject ) return originalPush.call(this, location, resolve, reject)
    return originalPush.call(this, location).catch((e)=>{console.log(123);})
}



export default router
