<template>
  <div id="app" style="height: 100%;">

    <router-view style="height: 100%;"></router-view>

  </div>
</template>

<script>
export default {
  name: 'app',
  
}
</script>

<style>
:root:root {
  --color-text: #000000;
  --container-width: 18px; /*组件宽度*/
  --container-height: 18px; /*组件高度*/
  --angle-size: 3px; /*四角边框效果尺寸*/
  --angle-weight: 1px; /*四角边框粗细，数值越小越粗*/
  --angle-color: #409eff;
  --scan-wake-size: 20px; /*扫描拖光的长度*/
  --scan-wake-color: #409eff; /*扫描拖光颜色*/
}
</style>
