<template id="temp">
  <!-- vue实例外创建 -->
  <div>
    <el-container >
      <el-header style="width: 100%; padding: 0px;height:auto;min-width:500px">
        <div class="temptop">
          <el-card class="box-card">
            <img
              class="yxtLogo"
              src="../assets/image/sanzhiwanglogin1.png"
              alt=""
              @click="gosecure()"
            />
            <div class="d-flex">
              <template v-if="usersubtype != 1">
                <el-tooltip content="首页" placement="bottom">
                <div class="d-flex-1" @click="shouye()">
                  <i class="el-icon-user"></i>
                  首页
                </div>
              </el-tooltip>

              <el-tooltip content="工单" placement="bottom">
                <div class="d-flex-1" @click="gongdan()">
                  <i class="el-icon-tickets"></i>
                  工单
                </div>
              </el-tooltip>

              <el-tooltip content="消息" placement="bottom">
                <div class="d-flex-1" @click="xiaoxi()">
                  <i v-if="unreadtotal == 0" class="el-icon-chat-round"></i>
                  <img
                    v-if="unreadtotal != 0"
                    src="../assets/image/weidu.png"
                    style="height: 18px; width: 18px"
                  />
                  消息
                </div>
              </el-tooltip>

              <!-- <el-badge :value="unreadtotal" :max="99" class="item">
                <el-tooltip content="消息" placement="bottom">
                <div class="d-flex-1" @click="xiaoxi()">
                  <i v-if="unreadtotal == 0" class="el-icon-chat-round"></i>
                  <img
                    v-if="unreadtotal != 0"
                    src="../assets/image/weidu.png"
                    style="height: 18px; width: 18px"
                  />
                  消息
                </div>
              </el-tooltip>
               
              </el-badge> -->

              <el-tooltip content="费用" placement="bottom">
                <div class="d-flex-1" @click="feiyong()">
                  <i class="el-icon-coin"></i>
                  费用
                </div>
              </el-tooltip>
              </template>

              <div class="logout" >
                <el-dropdown placement="bottom" @command="handleCommand" style="height: 40px;">
                  <el-avatar
                    class="user-avatar"
                    :size="40"
                    :src="touxiang"
                    icon="el-icon-user"
                  ></el-avatar>
                  <el-dropdown-menu class="user-dropdown-menu" style="min-width: 100px;">
                    <el-dropdown-item command="changePassword"
                      >个人主页</el-dropdown-item
                    >
                    <el-dropdown-item command="logout" @click="logout()"
                      >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </el-card>
        </div>
      </el-header>

      <el-container style="min-width: 1000px;">
        <el-aside :width="isNavOpen?'60px':'200px'" style="height: 100vh;">
              <!-- <button class="nav-toggle" @click="toggleNav">{{ isNavOpen ? '隐藏导航' : '显示导航' }}</button> -->
              <el-menu
                :unique-opened="true"
                :default-active="activeSubMenuIndex"
                :collapse="isNavOpen"
                :router="true"
                @updateActiveItem="handleUpdateActiveItem"
                style="height: 100%;"
              >
                <el-menu-item index="/secure" v-if="usersubtype != 1">
                  <i class="iconfont icon-zhuye1 el-icon2"></i>
                  <span slot="title">用户中心</span>
                </el-menu-item>
                <el-submenu index="2" v-if="usersubtype != 1">
                  <template slot="title">
                    <i class="iconfont icon-chanpin el-icon2"></i>
                    <span slot="title">产品与服务</span>
                  </template>
                  <el-menu-item index="/order"
                    ><i class="iconfont icon-dian"></i> 订购产品</el-menu-item
                  >
                  <el-menu-item index="/server"
                    ><i class="iconfont icon-dian"></i> 云服务器</el-menu-item
                  >
                  <el-menu-item index="/independence"
                    ><i class="iconfont icon-dian"></i> 独立服务器</el-menu-item
                  >
                  <el-menu-item index="/cabinet"
                    ><i class="iconfont icon-dian"></i>
                    机柜/托管租用</el-menu-item
                  >
                  <el-menu-item index="/otherProduct"
                    ><i class="iconfont icon-dian"></i> 所有产品</el-menu-item
                  >
                </el-submenu>
                <el-submenu index="3" v-if="usersubtype != 1">
                  <template slot="title">
                    <i class="iconfont icon-zhanghuyue el-icon2"></i>
                    <span slot="title">财务管理</span>
                  </template>
                  <el-menu-item index="/billList"
                    ><i class="iconfont icon-dian"></i> 账单列表</el-menu-item
                  >
                  <el-menu-item index="/recharge"
                    ><i class="iconfont icon-dian"></i> 账户充值</el-menu-item
                  >
                  <el-menu-item index="/record"
                    ><i class="iconfont icon-dian"></i> 交易记录</el-menu-item
                  >
                </el-submenu>
                <el-submenu index="4" v-if="usersubtype != 1">
                  <template slot="title">
                    <i class="iconfont icon-yonghu el-icon2"></i>
                    <span slot="title">账户管理</span>
                  </template>
                  <el-menu-item index="/authentication"
                    ><i class="iconfont icon-dian"></i> 实名认证</el-menu-item
                  >
                  <el-menu-item index="/work"
                    ><i class="iconfont icon-dian"></i> 工单列表</el-menu-item
                  >
                  <el-menu-item index="/messageList"
                    ><i class="iconfont icon-dian"></i> 消息列表</el-menu-item
                  >
                </el-submenu>

                <!-- <el-menu-item index="/firewall">
                  <i class="iconfont icon-fanghuoqiang el-icon2"></i>
                  <span slot="title">防火墙查询</span>
                </el-menu-item>
                <el-menu-item index="/whiteList">
                  <i class="iconfont icon-baimingdan1-copy el-icon2"></i>
                  <span slot="title">域名白名单</span>
                </el-menu-item>
                <el-menu-item index="/inventory" v-if="usersubtype != 1">
                  <i class="iconfont icon-kucun el-icon2" ></i>
                  <span slot="title">硬件库存列表</span>
                </el-menu-item> -->
              </el-menu>

            <!-- 播放提示音 -->
            <audio
              controls="controls"
              hidden
              src="../assets/提示音.mp3"
              ref="audio"
            ></audio>
        </el-aside>

        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { GetunReadMessage } from "../api/user";
export default {
  data() {
    return {
      
      activeSubMenuIndex: "/secure", // 存储选中的子菜单索引
      submitId: null,
      unreadtotal: null, //未读消息

      initialNum: 0,

      touxiang: require("@/assets/image/u=3061155316,329340046&fm=253&fmt=auto&app=138&f=JPEG.webp"),

      isNavOpen: false,

      usersubtype:null,
    };
  },
  watch: {
    $route(to, from) {
      this.activeSubMenuIndex = to.path;
      // console.log(to,from);
    },
  },
  created() {
    this.activeSubMenuIndex = this.$route.path;
    // console.log(this.$route.path);
    // console.log(this.activeSubMenuIndex);
    let customusernametitle = JSON.parse(
      localStorage.getItem("customusernametitle")
    );
    this.submitId = customusernametitle;
    // console.log(this.submitId);
    this.getunreadmessage();
    let timer = setInterval(() => {
      // console.log(new Date());
      this.getunreadmessage();
    }, 10000);
    this.$once("hook:beforeDestroy", () => {
      // console.log("-----------------清除定时器");
      clearInterval(timer);
    });
    this.checkCollapse();
    window.addEventListener("resize", this.checkCollapse);

    this.usersubtype = JSON.parse(localStorage.getItem("usersublogin"))
    console.log(this.usersubtype);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("resize", this.checkCollapse);
  },
  methods: {
    checkCollapse() {
      this.isNavOpen = window.innerWidth <= 768;
    },
    


    // menu
    handleUpdateActiveItem(index) {
      // console.log(index);
      this.activeItem = index;
    },
    logout() {
      this.$confirm("是否要退出登录", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.clear();
          this.$router.push("/login");
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    // 个人主页或者退出登录
    handleCommand(command) {
      // 退出登录 
      if (command === "logout") {
        this.logout();
        // 个人主页
      } else if (command === "changePassword") {
        // 处理个人主页的逻辑
        // 检查当前路径是否已经是 '/secure'
        if (this.$route.path !== "/secure") {
          this.$router.push("/secure");
        }
      }
    },

    // 点击首页跳转
    shouye() {
      // 检查当前路径是否已经是首页
      if (this.$route.path !== "/secure") {
        this.$router.push("/secure");
      }
    },
    // 点击消息跳转
    xiaoxi() {
      if (this.$route.path !== "/messageList") {
        this.$router.push("/messageList");
      }
    },
    // 点击费用跳转
    feiyong() {
      // 检查当前路径是否是费用
      if (this.$route.path !== "/recharge") {
        this.$router.push("/recharge");
      }
    },
    // 点击工单跳转
    gongdan() {
      // 检查当前路径是否是费用
      if (this.$route.path !== "/work") {
        this.$router.push("/work");
      }
    },
    // 点击图片跳转
    gosecure() {
      if(this.usersubtype == 1){
        return
      }
      if (this.$route.path !== "/secure") {
        this.$router.push("/secure"); 
      }
    },
    // 获取未读消息数据
    async getunreadmessage() {
      let data = await GetunReadMessage({
        belongUser: this.submitId,
        status: 0,
        hideLoading: true,
      });
      // console.log(data);
      // console.log(this.initialNum);
      if (data.code == 200) {
        this.unreadtotal = data.total;
        if (this.initialNum == 0) {
          this.initialNum = data.data[0].id;
        } else if (this.initialNum < data.data[0].id) {
          this.initialNum = data.data[0].id;
          // this.notifyMe1();
        }
      }
    },

    // 弹窗方法
    notifyMe1() {
      if (!("Notification" in window)) {
        // 检查浏览器是否支持通知
        alert("当前浏览器不支持桌面通知");
      } else if (Notification.permission === "granted") {
        // 检查是否已授予通知权限；如果是的话，创建一个通知
        const notification = new Notification("你好！", {
          body: "\n您的需求已经处理完成,请查看",
          requireInteraction: true,
          silent: false,
        });
        notification.pageUrl = window.location.href;

        notification.onclick = function () {
          if (notification.pageUrl) {
            window.location.href = notification.pageUrl; // 跳转回原页面
          }
        };

        this.$refs.audio.currentTime = 0; //从头开始播放提示音
        this.$refs.audio.play(); //播放

        // …
      } else if (Notification.permission !== "denied") {
        // 我们需要征求用户的许可
        Notification.requestPermission().then((permission) => {
          // 如果用户接受，我们就创建一个通知
          if (permission === "granted") {
            const notification = new Notification("你好！");
            // …
          }
        });
      }

      // 最后，如果用户拒绝了通知，并且你想尊重用户的选择，则无需再打扰他们
    },
  },
};
</script>

<style scoped>
::v-deep .box-card .el-card__body {
  padding: 0px !important;
  display: flex;
  justify-content: space-between;
  align-items: center
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  /* width: 200px; */
  /* min-width: 200px; */
  /* min-height: 400px; */
  float: left;
}
.box-card img {
  width: 14%;
  float: left;
}
.temptop {
  width: 100%;
  min-width: 980px;
  /* background-color: blue; */
}
.logout {
  float: right;
  margin: 10px 40px 10px 40px !important;
}
.el-submenu .el-menu-item {
  font-size: 13px;
  /* height: 35px; */
  /* margin: 12px 0; */
}
.el-submenu.is-active > .el-submenu__title {
  color: #409eff !important;
}

/* .indexright {
  float: left;
  width: 83%;
  padding: 15px 22px;
  min-width: 1200px;
} */

.boxmiddle {
  /* display: flex; */
  height: 100%;
}
.el-dropdown .user-avatar img {
  width: 100%;
  height: 100%;
}
.d-flex {
  display: flex;
  height: 60px;
}
.d-flex-1 {
  padding: 22px 18px;
  color: #555555;
  font-size: 0.8525rem;
  cursor: pointer;
  min-width: 46px;
}

.el-icon2 {
  vertical-align: middle;
  margin-right: 8px;
  width: 24px;
  text-align: center;
  font-size: 20px;
}

.my-class {
  display: block;
}

.toggle-button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.my-class {
  display: block;
}

.yxtLogo{
  cursor: pointer;
}
@media (max-width: 768px) {
  .yxtLogo {
    display: none;
  }
  .el-col{
    width: 100%;
  }
}
::v-deep .el-badge__content.is-fixed {
    position: absolute;
    top: 20px;
    right: 26px;
    transform: translateY(-50%) translateX(100%);
}
</style>
