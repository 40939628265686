import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueParticles from 'vue-particles'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/global.scss'
import './assets/iconfont/iconfont.css'
import  "./utils/WxLogin.js"

// 开发者模式
Vue.config.productionTip = false

// 全局处理错误 
Vue.config.errorHandler = function (err, vm, info) {
  // 全局错误处理函数
  console.error('Global error:', err, info);
  // 在此处可以进行其他错误处理，比如向日志服务器发送错误信息
};


Vue.use(VueParticles)
Vue.use(Element)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
