<template>
  <div>
    <el-row align="top" class="top">
      <div link style="padding-left: 10px">
        <img style="width: 150px; height: 100%" src="@/assets/image/sanzhiwanglogin1.png" />
      </div>
      <div style="flex-grow: 1" />

      <div class="tip" @click="formChange">
        <span
          style="
            top: 83px;
            left: 44px;
            color: #fff;
            position: relative;
            font-size: 18px;
          "
        >
          {{ formType == "login" ? "注册" : "登录" }}
        </span>
      </div>
      <!-- <el-radio-group v-model="formType" size="large">
        <el-radio-button label="用户登录" value="login" />
        <el-radio-button label="免费注册" value="register" />
      </el-radio-group> -->
    </el-row>
    <el-row justify="center" align="middle" style="display: flex">
      <div class="left-image-div">
        <!-- <div class="bottom-content">Copyright @ 2024 山东三支网网络科技有限公司</div> -->
      </div>
      <div class="login-form-div">
        <div class="login-form" v-if="formType == 'login'">
          <el-tabs
            v-model="activeName"
            class="demo-tabs"
            @tab-click="handleClick"
            style="
              padding: 10px;
              width: 100%;
              max-width: 420px;
              margin-left: auto;
              margin-right: auto;
            "
          >
            <el-tab-pane label="账号密码登录" name="accountLogin">
              <el-form :model="loginForm" style="width: 100%">
                <div style="maring: 10px">
                  <el-input
                  ref="inputRefPassword"
                  autofocus
                    v-model="loginForm.account"
                    placeholder="请输入登录账号"
                    style="height: 50px;margin-bottom:10px;margin-top:30px"
                  />
                </div>
                <div style="maring: 10px">
                  <el-input
                    type="password"
                    show-password
                    v-model="loginForm.password"
                    placeholder="请输入登录密码"
                    style="height: 50px;margin-bottom:10px"
                  />
                </div>
                <div style="font-size: 14px;color: #979797;">还没有账号？立即 <span @click="formChange" class="freezhuce" style="color:#667fa0">免费注册</span></div>

                <el-form-item>
                  <el-button
                    type="primary"
                    @click.prevent="accountlogin()"
                    style="width: 100%; height: 40px;margin-top:20px"
                  >
                    登录
                  </el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="手机号登录" name="phoneLogin">
              <el-form :model="loginForm2" style="width: 100%">
                <div label="">
                  <el-input
                  ref="inputRef"
                  autofocus
                    v-model="loginForm2.phone"
                    placeholder="请输入手机号"
                    style="height: 50px;margin-bottom:10px;margin-top:30px"
                  />
                </div>
                <div label="" style="display: flex">
                  <el-input
                    type="password"
                    v-model="loginForm2.smsCode"
                    placeholder="请输入验证码"
                    style="flex: 1; height: 50px;margin-bottom:10px"
                  />
                  <el-button
                    style="
                      border: 0px;
                      border-left: 1px solid var(--el-border-color);
                      border-radius: 0px;
                      height: 40px;
                    "
                    :disabled="isWaiting"
                    @click="getregistercode()"
                  >
                    {{ btnText }}
                  </el-button>
                </div>

                <el-form-item>
                  <el-button
                    type="primary"
                    @click="phonelogin()"
                    style="width: 100%; height: 40px;margin-top:20px"
                  >
                    登录
                  </el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>

            <!-- 子账号 -->
            <el-tab-pane label="子账号登录" name="childLogin">
              <el-form :model="loginForm3" style="width: 100%">
                <div label="">
                  <el-input
                  ref="childInputRef"
                  autofocus
                    v-model="loginForm3.account"
                    placeholder="请输入登录账号"
                    style="height: 50px;margin-bottom:10px;margin-top:30px"
                  />
                </div>
                <div label="" style="display: flex">
                  <el-input
                    type="password"
                    show-password
                    v-model="loginForm3.password"
                    placeholder="请输入登陆密码"
                    style="flex: 1; height: 50px;margin-bottom:10px"
                  />
                </div>

                <el-form-item>
                  <el-button
                    type="primary"
                    @click="childlogin()"
                    style="width: 100%; height: 40px;margin-top:20px"
                  >
                    登录
                  </el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
          </el-tabs>
          <!-- <div
            style="
              width: 100%;
              padding: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <el-divider>
              <div style="display: flex; align-items: center">
                <div class="scan-container" style="margin-right: 6px">
                  <div class="scan-container1">
                    <div class="scan-container-corner corner-top-left"></div>

                    <div class="scan-container-corner corner-top-right"></div>
                    <div class="scan-container-corner corner-bottom-left"></div>
                    <div
                      class="scan-container-corner corner-bottom-right"
                    ></div>
                  </div>
                  <div class="scan-container2">
                    <img
                      src="@/assets/qrcode.png"
                      style="
                        width: inherit;
                        height: inherit;
                        object-fit: contain;
                      "
                    />
                  </div>
                  <div class="scan-container3">
                    <div class="scan-animate"></div>
                  </div>
                </div>
                微信扫码登录
              </div>
            </el-divider>
            <div id="login_container" style="height: 150px" />
            <div style="display: flex; align-items: center; margin-top: 10px">
              <span style="font-size: 12px"
                >打开微信【扫一扫】功能，扫描二维码</span
              >
            </div>
          </div> -->
        </div>
        <!-- 注册表单 -->
        <div class="login-form" v-if="formType == 'register'">
          <p style="font-size: 22px; margin-bottom: 15%">用户注册</p>
          <el-form
            ref="zhuceForm"
            :model="zhuceForm"
            :rules="zhucedata"
            status-icon
            style="width: 90%"
          >
            <el-form-item label="" prop="phone">
              <el-input  autofocus v-model="zhuceForm.phone" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item class="phoneyzm" prop="smsCode">
              <el-input
              
                type="password"
                v-model="zhuceForm.smsCode"
                placeholder="请输入验证码"
              />
              <el-button
                style="
                  border: 0px;
                  border-left: 1px solid var(--el-border-color);
                  border-radius: 0px;
                  height: 40px;
                "
                :disabled="ZCphonecmsWaiting"
                @click="getZCphoneyanzheng()"
                class="zcbut"
                >{{ ZCphonecmsText }}</el-button
              >
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input
                v-model="zhuceForm.password"
                placeholder="请设置密码（6-20位数字大小写字母组合）"
              />
            </el-form-item>
            <el-form-item label="" prop="repeatPassword">
              <el-input
                v-model="zhuceForm.repeatPassword"
                placeholder="请确认密码"
              />
            </el-form-item >
            <el-form-item prop="sysUserId" v-if="saleType == true">
              <el-select
                v-model="zhuceForm.sysUserId"
                placeholder="请选择销售"
                size="large"
                style="width: 100%"
              >
                <el-option
                  v-for="item in sysData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="">
              <el-input
                v-model="zhuceForm.qq"
                placeholder="请输入QQ(非必填)"
              />
            </el-form-item >

            <el-form-item style="margin-top: 20px">
              <el-button
                type="primary"
                @click="checkForm()"
                style="width: 100%; height: 40px"
              >
                注册
              </el-button>
            </el-form-item>
          </el-form>
        </div>

      </div>
    </el-row>
    <el-row align="bottom" class="bottom">
      <div class="bottom-content">
        <p>Copyright © 2024-2024 山东三支网网络科技有限公司 版权所有</p>
        <p>
          <a
            href="https://dxzhgl.miit.gov.cn/#/home"
            target="_blank"
            style="color: #494a4a;text-decoration: none"
          >
            《中华人民共和国增值电信业务经营许可证》编号：鲁ICP16037675号-2
          </a>
          <img style="width: 14px" src="@/assets/wangbei.jpg" />
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37040302000414号"
            target="_blank"
            style="color: #494a4a;text-decoration: none"
          >
            鲁公网安备 37040302000414号
          </a>
        </p>
      </div>
    </el-row>
  </div>
</template>

<script>
import {
  UserLogin,
  GetPhoneCode,
  UserRegister,
  UserWxlogin,
  GetSysSale,
  GetSecurityEmail,
  UserSubLogin
} from "../api/user";
import { encrypt } from "@/plugins/encrypt";

export default {
  data() {
    return {
      // tabs标签页
      activeName: "accountLogin",
      logintype: 1,
      // 账号登陆form
      loginForm: {
        account: "",
        password: "",
      },
      // 手机号登录form
      loginForm2: {
        phone: "",
        smsCode: "",
      },
      // 子账号登录 
      loginForm3: {
        account: "",
        password: "",
      },
      // 注册form
      registerForm: {
        phone: "",
        smsCode: "",
      },
      btnText: "获取验证码",
      isWaiting: false,
      ZCphonecmsText: "获取验证码", //注册时获取手机号验证码
      ZCphonecmsWaiting: false,
      ZCyxcmsText: "获取验证码", //注册时获取邮箱验证码
      ZCyxcmsWaiting: false,
      // 微信
      wxLoginOpion: {
        appid: "wx22f223eabee2015c",
        redirectUrl: "/login",
      },
      // 微信登陆接口参数
      wxlogincs: {
        code: "",
        state: "",
      },

      identifyCodes: "1234567890",
      identifyCode: "",
      // 校验规则
      rulesdata1: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      // 销售Data
      sysData: [],
      zhuceCard: false, //默认等于false,false为默认是登录页面
      zhuceForm: {},
      // 验证规则
      zhucedata: {
        account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        // password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "密码长度在 6 到 20 个字符",
            trigger: "blur",
          },
          { validator: this.validatePassword, trigger: "blur" },
        ],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        emailCode: [
          { required: true, message: "请输入邮箱验证码", trigger: "blur" },
        ],
        phone: [
          {
            validator: this.validatePhone,
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
        smsCode: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        sysUserId: [
          { required: true, message: "请选择销售", trigger: "change" },
        ],
        repeatPassword: [
          { validator: this.validateRepeatPassword, trigger: "blur" },
        ],
      },

      saleType: true, //展示销售选项,如果为false就不展示
      saleId: "", //复制过来路径的id

      formType: "login",
    };
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeyDown);

    console.log(this.getQueryString("code"));
    // 判断是不是销售的地址过来的
    // console.log(this.$route.query);
    if (this.$route.query.saleId) {
      // console.log("有id");
      this.saleId = this.$route.query.saleId;
      // console.log(this.saleId);
      this.saleType = false;
      this.zhuceForm.sysUserId = this.saleId;
      this.formType = 'register'
    } else {
      // console.log("无id");
    }
    // console.log(this.zhuceForm);
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    // 原始处理 不好使就用这个
    // let code = location.href.split("?")[1];
    // if (code) {
    //   code = code.substring(5, code.indexOf("&"));
    //   this.wxlogincs.code = code;
    // }
    // let state = location.href.split("?")[1];
    // state = state.split("state=")[1].split("#")[0];
    // this.wxlogincs.state = state;

    let href = location.href;
    if (href.includes("?")) {
      let code = href.split("?")[1];
      console.log(code.includes("saleId"));
      console.log(code);
      // 继续处理 code 的逻辑
      if (code.includes("code")) {
        
        code = this.getQueryString("code");
        // code = code.substring(5, code.indexOf("&"));
        this.wxlogincs.code = code;
        this.wxloginjk();
      }
    }
    let state = "";
    if (href.includes("state=")) {
      state = href.split("state=")[1].split("#")[0];
      // 继续处理 state 的逻辑
      this.wxlogincs.state = state;
    }
    // 根据路径做判断

    this.getsyslale();

    // const s = document.createElement('script');
    // s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
    // this.$nextTick(() => {
    //     document.body.appendChild(s);
    //   });
    this.initWeChatLogin();
    
  },
  beforeDestroy() {
    // 在组件销毁时解除键盘事件注册
    document.removeEventListener("keydown", this.handleKeyDown);
  },
  methods: {
    
    formChange() {
      if (this.formType == "login") {
        this.formType = "register";
      } else if (this.formType == "register") {
        this.formType = "login";
        this.$nextTick(() => {
          this.initWeChatLogin();
        });
      }
      console.log(this.formType);
    },
    // 点击回车
    handleKeyDown(event) {
      if (event.key === "Enter") {
        if (this.activeName == "accountLogin") {
          console.log("账号登录");
          this.accountlogin();
        } else if (this.activeName == "phoneLogin") {
          console.log("手机号登录");
          this.phonelogin();
        }else if (this.activeName == "childLogin") {
          console.log("子账号登录");
          this.childlogin();
        }
      }
    },
    // 获取url参数的token方法
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    // tabs标签页
    handleClick(tab, event) {
      console.log(tab, event);
      
      if(tab.label == '手机号登录'){
        this.$nextTick(()=>{
        if(this.$refs.inputRef){
          this.$refs.inputRef.focus()
          }
        })
      }
      if(tab.label == '账号密码登录'){
        this.$nextTick(()=>{
        if(this.$refs.inputRefPassword){
          this.$refs.inputRefPassword.focus()
          }
        })
      }
      if(tab.label == '子账号登录'){
        this.$nextTick(()=>{
        if(this.$refs.childInputRef){
          this.$refs.childInputRef.focus()
          }
        })
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },

    // 账号登录按钮
    async accountlogin() {
      // 判断是否输入账号和密码
      if (this.loginForm.account != "" && this.loginForm.password != "") {
        // console.log(encrypt(this.loginForm.password));
        this.loginForm.password = encrypt(this.loginForm.password);
        let response = await UserLogin(this.loginForm);
        // console.log(response);
        if (response.code == 200) {
          // 请求成功
          // console.log(encrypt(this.loginForm.password))
          // console.log(response);
          this.$message.success("登录成功");
          this.loginForm.account = "";
          this.loginForm.password = "";
          // token存缓存
          localStorage.setItem("customlogintoken", response.data.token);
          localStorage.setItem("usersubType", JSON.stringify(response.data.user.user));
          this.$router.push("/secure");
          // 存用户名id缓存
          localStorage.setItem(
            "customusernametitle",
            response.data.user.user.id
          );
        } else {
          this.$message.error(response.msg);
          this.loginForm.account = "";
          this.loginForm.password = "";
        }
      } else {
        this.$message.error("请填写完整的登录信息");
        this.loginForm.account = "";
        this.loginForm.password = "";
      }
    },
    // 注册时获取验证码
    async getregistercode() {
      this.btnText = Number(60);
      this.isWaiting = true;
      this.time();
      let phone = this.registerForm.phone || this.loginForm2.phone;
      let data = await GetPhoneCode({ phone: phone });
      // console.log(data);
      if (data.code == 200) {
        this.$message.success("发送成功");
      } else {
        this.$message.error(data.msg);
      }
    },
    time() {
      let time = setInterval(() => {
        if (this.btnText > 0) {
          this.btnText--;
        } else {
          clearInterval(time);
          this.btnText = "获取验证码";
          this.isWaiting = false;
        }
      }, 1000);
    },
    // 点击微信登录
    // wxlogin() {
    //   let redirectUrl =
    //     "https://caiwu.yxtidc.com" + this.wxLoginOpion.redirectUrl; //process.env.VUE_APP_BASE_API+redirectUrl = encodeURIComponent(redirectUrl)
    //   let state = this.randomState();
    //   let url = `https://open.weixin.qq.com/connect/qrconnect?appid=${this.wxLoginOpion.appid}`;
    //   url += `&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_login`;
    //   url += `&state=${state}#wechat_wechat_redirect`;
    //   window.location.href = url;
    // },

    // 微信登陆内嵌页面
    initWeChatLogin() {
      var wxLogin = new WxLogin({
        self_redirect: false,
        id: "login_container", // 确保这个 id 是唯一的，并且不会被其他操作修改
        appid: this.wxLoginOpion.appid,
        scope: "snsapi_login",
        // redirect_uri: "https://caiwu.yxtidc.com/login",
        redirect_uri: "https://caiwu.szw.com/login",
        state: this.randomState(),
        style: "",
        stylelite: 1,
        fast_login: 1,
      });
    },

    // state生成随机字符串
    randomState() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let state = "";
      for (let i = 0; i < 16; i++) {
        state += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return state;
    },
    // 微信登陆接口
    async wxloginjk() {
      // console.log(this.wxlogincs);
      let obj = this.wxlogincs;
      let data = await UserWxlogin(obj);
      if (data.code == 200) {
        console.log(data);
        this.$message.success("登录成功");
        localStorage.setItem("customlogintoken", data.data.token);
        localStorage.setItem("customusernametitle", data.data.user.user.id);
        localStorage.setItem("usersubType", JSON.stringify(data.data.user.user));
        this.$router.push("/secure");
      } else {
        this.$message.error(data.msg);
        this.formType = 'register'
      }
      // console.log(data);
    },

    // 手机号按钮登录
    async phonelogin() {
      let form = this.loginForm2;
      let data = await UserLogin(form);
      // console.log(data);
      if (data.code == 200) {
        this.$message.success("登录成功");
        localStorage.setItem("customlogintoken", data.data.token);
        localStorage.setItem("customusernametitle", data.data.user.user.id);
        localStorage.setItem("usersubType", JSON.stringify(data.data.user.user));
        this.$router.push("/secure");
      } else {
        this.$message.error(data.msg);
      }
    },
    // 获取销售接口
    async getsyslale() {
      let data = await GetSysSale();
      // console.log(data);
      if (data.code == 200) {
        this.sysData = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    // 重置按钮
    clearinput() {
      this.loginForm = {};
      this.loginForm2 = {};
      this.registerForm = {};
    },

    // 注册时发送邮箱验证码
    getzhuceyanzheng() {
      this.getemaliSms();
    },
    // 获取发送邮箱验证码数据
    async getemaliSms() {
      this.ZCyxcmsText = Number(60);
      this.ZCyxcmsWaiting = true;
      this.youxiangYzTime();
      let email = this.zhuceForm.email;
      let data = await GetSecurityEmail({ email: email });
      // console.log(data);
      if (data.code == 200) {
        this.$message.success("操作成功");
      } else {
        this.$message.error(data.msg);
      }
    },
    youxiangYzTime() {
      let youxiangYzTime = setInterval(() => {
        if (this.ZCyxcmsText > 0) {
          this.ZCyxcmsText--;
        } else {
          clearInterval(youxiangYzTime);
          (this.ZCyxcmsText = "获取验证码"), (this.ZCyxcmsWaiting = false);
        }
      }, 1000);
    },
    // 注册时发送手机号验证码
    getZCphoneyanzheng() {
      this.getzhucephonecode();
    },
    // 注册时点击发送手机号按钮
    async getzhucephonecode() {
      this.ZCphonecmsText = Number(60);
      this.ZCphonecmsWaiting = true;
      this.phoneYzTime();
      let phone = this.zhuceForm.phone;
      let data = await GetPhoneCode({ phone: phone });
      // console.log(data);
      if (data.code == 200) {
        this.$message.success("操作成功");
      } else {
        this.$message.error(data.msg);
      }
    },
    phoneYzTime() {
      let phoneYzTime = setInterval(() => {
        if (this.ZCphonecmsText > 0) {
          this.ZCphonecmsText--;
        } else {
          clearInterval(phoneYzTime);
          (this.ZCphonecmsText = "获取验证码"),
            (this.ZCphonecmsWaiting = false);
        }
      }, 1000);
    },
    // 点击注册按钮
    zhuce() {
      this.zhuceCard = true;
    },

    // 返回登陆按钮
    loginback() {
      this.zhuceCard = false;
    },
    // 订单校验方法
    checkForm() {
      this.$refs.zhuceForm.validate((valid, msg) => {
        if (valid) {
          this.zhuceyemianZC();
        } else {
          this.$message.error("请将表格填写完整");
          return false;
        }
      });
    },
    // 点击注册页面的注册按钮
    zhuceyemianZC() {
      // console.log(this.zhuceForm);
      this.zhuceForm.password = encrypt(this.zhuceForm.password);
      // console.log(this.zhuceForm.password);
      this.register();
    },
    // 点击注册页面注册按钮的数据
    async register() {
      let form = this.zhuceForm;
      // console.log(form);
      let data = await UserRegister(form);
      // console.log(data);
      if (data.code == 200) {
        this.$message.success("注册成功");
        localStorage.setItem("customlogintoken", data.data.token);
        localStorage.setItem("customusernametitle", data.data.user.user.id);
        localStorage.setItem("usersubType", JSON.stringify(data.data.user.user));
        this.$router.push("/secure");
      } else {
        this.$message.error(data.msg);
        this.zhuceForm.password = "";
        this.zhuceForm.repeatPassword = "";
      }
    },
    // 判断两次密码输入的一样
    validateRepeatPassword(rule, value, callback) {
      if (value !== this.zhuceForm.password) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    },
    // 判断密码是不是数字加字母
    validatePassword(rule, value, callback) {
      const regExp =
        /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()-=,.\/])[0-9A-Za-z!@#$%^&*()-=,.\/]{6,20}$/;
      if (regExp.test(value)) {
        callback();
      } else {
        callback(new Error("密码必须包含数字和字母或其他字符"));
      }
    },
    validatePhone(rule, value, callback) {
      const regExp = /^[0-9]{11,20}$/;
      if (regExp.test(value)) {
        callback();
      } else {
        callback(new Error("请输入正确的手机号"));
      }
    },

    // 子账号登录按钮
    childlogin(){
      console.log(this.loginForm3);
      this.usersublogin()
    },
    // 子账号登陆接口
    async usersublogin(){
      this.loginForm3.password = encrypt(this.loginForm3.password)
      let data = await UserSubLogin(this.loginForm3)
      if(data.code == 200){
        this.$message.success("登录成功");
          // token存缓存
          localStorage.setItem("customlogintoken", data.data.token);
          localStorage.setItem("customusernametitle", data.data.user.user.id);
          localStorage.setItem("usersublogin", data.data.user.user.isUserSub);
          localStorage.setItem("usersubType", JSON.stringify(data.data.user.user));
          this.$router.push("/whiteList");
      }else{
        this.$message.error(data.msg)
      }
    }
  },
};
</script>

<style scoped>
.el-avatar {
  --el-avatar-text-color: var(--el-color-white);
  --el-avatar-bg-color: #ffffff;
}
.loginbody {
  /* border: 1px solid #21a7e1; */
  /* box-shadow: 5px 5px 10px 10px rgba(24, 68, 124, 0.4); */
  padding-top: 20px;
  /* border-radius: 6px; */
  position: relative;
}
.bottom-content {
  margin-top: auto; /* 推到底部 */
  text-align: center; /* 如果你想要居中内容 */
  /* padding-bottom: 1rem; */
  color: #494a4a;
  font-size: 12px;
}
.left-image-div {
  width: 60%;
  margin: 0px;
  padding: 0px;
  height: 100vh;
  background-image: url("/src/assets/bgdd.png");
  background-size: fit; /* 图片覆盖整个容器，可能会被裁剪 */
  background-repeat: no-repeat;
  background-position: center; /* 图片居中显示 */
  height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
}
.login-form-div {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  /* padding: 2em 1rem 1rem; */
  border-left: 1px solid #dcdfe6;
}
::v-deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-radius: 0;
}
::v-deep .el-radio-button__inner {
  padding: 15px;
}
::v-deep .el-tabs__nav-wrap:after {
  background-color: #ffffff;
}
::v-deep .el-tabs__nav .is-top {
  font-size: 18px;
}
@media screen and (max-width: 800px) {
  .left-image-div {
    display: none;
  }
  .login-form-div {
    width: 100%;
  }
}
::v-deep .el-select__wrapper {
  box-shadow: none !important;
  border: 0;
  background: #fff;
  border-bottom: 1px solid var(--el-border-color);
  border-radius: 0;
}

::v-deep .el-input,
.el-input__wrapper {
  --el-input-border-color: #fff;
  /* --el-input-hover-border-color: #fff;
  --el-input-focus-border-color: #fff; */
  box-shadow: none !important;
  border: 0;
  background: #fff;
  border-bottom: 1px solid var(--el-border-color);
  border-radius: 0;
}
.el-form-item.is-error .el-input,
.el-input,
.el-select {
  --el-input-focus-border-color: transparent;
}
::v-deep.el-input__wrapper.is-focus,
.el-select__wrapper.is-focused {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #409eff;
  border-radius: 0px;
}
::v-deep.el-select__wrapper.is-focused {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #409eff;
  border-radius: 0px;
}
::v-deep.el-input__wrapper:hover {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #409eff;
  border-radius: 0px;
}
::v-deep .el-select__wrapper:hover {
  box-shadow: none;
  border: 0;
  border-bottom: 1px solid #409eff;
  border-radius: 0px;
}

.scan-container-corner {
  position: absolute;
  width: var(--angle-size);
  height: var(--angle-size);
  background-color: var(--angle-color);
}
.corner-top-left {
  top: calc(0px - var(--angle-weight));
  left: calc(0px - var(--angle-weight));
}
.corner-top-right {
  top: calc(0px - var(--angle-weight));
  right: calc(0px - var(--angle-weight));
}
.corner-bottom-left {
  bottom: calc(0px - var(--angle-weight));
  left: calc(0px - var(--angle-weight));
}
.corner-bottom-right {
  bottom: calc(0px - var(--angle-weight));
  right: calc(0px - var(--angle-weight));
}

.scan-container {
  width: var(--container-width);
  height: var(--container-height);
}
.scan-container1 {
  position: absolute;
  width: inherit;
  height: inherit;
  z-index: 0;
}
.scan-container2 {
  position: absolute;
  width: inherit;
  height: inherit;
  z-index: 10;
  background-color: white;
}
.scan-container3 {
  position: absolute;
  width: inherit;
  height: inherit;
  z-index: 20;
  overflow: hidden;
}
.scan-animate {
  width: inherit;
  height: var(--scan-wake-size);
  animation: scan 3s ease-in-out infinite;
}

@keyframes scan {
  0% {
    /*最开始块从顶部往下移动，块的位置需要溢出容器，处于容器顶部上方，块的背景颜色从底部到顶部由绿色逐渐透明*/
    margin-top: calc(0px - var(--scan-wake-size));
    background: linear-gradient(#0000 0%, var(--scan-wake-color) 100%);
  }
  49% {
    /*动画进行到一半时间时，块必须移动到容器底部并溢出，完成从上到下扫描效果*/
    margin-top: var(--container-height);
    background: linear-gradient(#0000 0%, var(--scan-wake-color) 100%);
  }
  50% {
    /*调转颜色方向，准备往回扫（从下往上）*/
    margin-top: var(--container-height);
    background: linear-gradient(var(--scan-wake-color) 0%, #0000 100%);
  }
  100% {
    /*往回扫*/
    margin-top: calc(0px - var(--scan-wake-size));
    background: linear-gradient(var(--scan-wake-color) 0%, #0000 100%);
  }
}

.top {
  position: fixed;
  top: 0px;
  padding: 0px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  /* background-color: transparent; */
}
.login-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #d4d7de; */
  border-radius: 5px;
  margin: 10px;
}
.top .tip {
  position: absolute;
  height: 120px;
  width: 120px;
  right: -60px;
  top: -60px;
  transform: rotate(45deg);
  background-color: #409eff;
  font-size: 12px;
  cursor: pointer;
}
.bottom {
  position: fixed;
  bottom: 0px;
  padding: 10px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  z-index: 1000;
  width: 100%;
}
::v-deep .phoneyzm .el-form-item__content {
  display: flex;
}
::v-deep .el-input__inner{
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
}

::v-deep iframe{
  width: 150px;
  height: 150px;
}
.freezhuce {
  cursor: pointer;
}
::v-deep .freezhuce:hover{
  cursor: pointer;
  color: red !important;
}
</style>
