import request from '../utils/request'

// 客户登录接口
export const UserLogin = (data) => {
    return request({
      url: 'user/login',
      method: 'post',
      data,
    })
  }

// 客户注册接口 
export const UserRegister = (data) => {
    return request({
      url: 'user/register',
      method: 'post',
      data,
    })
  }

  // 短信验证接口 
  export const GetPhoneCode = (data) => {
    return request({
      url: 'sms/verificationCode',
      method: 'get',
      params:data,
    })
  }

// 微信登陆接口 
export const UserWxlogin = (data) => {
  return request({
    url: 'user/wxlogin',
    method: 'post',
    data,
  })
}

//获取销售
export const GetSysSale = (data) => {
  return request({
    url: 'sysUser/sale',
    method: 'get',
    params:data,
  })
}

//  发送邮箱验证码数据
export const GetSecurityEmail = (data) => {
  return request({
    url: 'email/emailCode',
    method: 'get',
    params:data,
  })
}

// 未读消息 
export const GetunReadMessage = (data) => {
  return request({
    url: 'message/unReadMessage',
    method: 'get',
    params:data,
  })
}

// 子账号登陆 user/loginSub
export const UserSubLogin = (data) => {
  return request({
    url: 'user/loginSub',
    method: 'post',
    data,
  })
}