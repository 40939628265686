import JSEncrypt from "jsencrypt";


const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTsr1bN4Vmrh9mhtLdVFRl4+/0XrYHcmnP+UqIkP7TzfmQoso/TDzkx6Ek+G2T2pZv/Xc9RkH7RfRawN4QcFv/rDx+yi3PKuQsH/MvENpXeytNR0IyHOmKCSfUrs3Bnn/VnVLPPgPupWvNH3+7y88E/By9NdxunX7gBCJGK0qp1QIDAQAB'

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey) // 设置公钥
  return encryptor.encrypt(txt) // 对需要加密的数据进行加密
}

